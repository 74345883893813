// Imports from React
import React, { Suspense, useEffect } from "react";

// Imports from React-Router-Dom
import { Routes, Route } from "react-router-dom";

// Imports for page analytics
import ReactGA from "react-ga";
import CookieConsent from "react-cookie-consent";

// Import of Pages
import Homepage from "./pages/Homepage";
import AboutUs from "./pages/AboutUs";
import Team from "./pages/Team";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";

// Imports for styling
import GlobalStyle from "./styles/GlobalStyle";
import styled from "styled-components";

ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <StyledApp>
      <Suspense fallback={null}>
        <GlobalStyle />
        <Routes>
          <Route index element={<Homepage />} />
          <Route path="*" element={<Homepage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </Suspense>
      <CookieConsent
        location="bottom"
        buttonText="I agree"
        style={{ background: "#ff7b00" }}
        buttonStyle={{
          color: "#ff7b00",
          fontSize: "13px",
          background: "black",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <a
          style={{
            textDecoration: "underline",
            marginLeft: "10px",
            color: "black",
          }}
          href="/privacy"
        >
          learn more
        </a>
      </CookieConsent>
    </StyledApp>
  );
};

// Styled Components
// window.addEventListener("resize", () => {
//   let vh = window.innerHeight * 0.01;
//   document.documentElement.style.setProperty("--vh", `${vh}px`);
// });

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

const StyledApp = styled.div`
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default App;
