// Import of components
import Navigation from "../components/Navigation";

// Import of team data
import teammembers from "../assets/data/teammembers";

// Imports for multi language
import { useTranslation } from "react-i18next";

// Imports for styling
import styled from "styled-components";

const Team = () => {
  const { t } = useTranslation();

  return (
    <StyledTeam>
      <Navigation />
      <TeamContent>
        <TeamHeading>Who we are</TeamHeading>
        <Members>
          {teammembers.map((member) => {
            return (
              <Member key={member.name}>
                <MemberImage src={member.image} alt={member.name} />
                <MemberText>
                  <MemberName>{member.name}</MemberName>
                  <MemberDescription>
                    {t(`team${member.name}.label`)}
                  </MemberDescription>
                </MemberText>
              </Member>
            );
          })}
        </Members>
      </TeamContent>
    </StyledTeam>
  );
};

// Styled components
const StyledTeam = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const TeamContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 120px 150px 5vh 150px;
  @media (orientation: portrait) {
    padding: 150px 30px 40px 30px;
    align-items: center;
  }
`;

const TeamHeading = styled.h1`
  margin-bottom: 70px;
  font-weight: 400;
  font-size: 70px;
  line-height: 88px;
  color: #000000;
  @media (orientation: portrait) {
    width: 100%;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 50px;
  }
`;

const Members = styled.div`
  display: flex;
  flex-direction: column;
`;

const Member = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  @media (orientation: portrait) {
    flex-direction: column;
    margin-bottom: 40px;
  }
`;

const MemberImage = styled.img`
  width: 136px;
  height: 136px;
  margin-right: 50px;
  border-radius: 8px;
  @media (orientation: portrait) {
    margin: 0 0 20px 0;
  }
`;

const MemberText = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

const MemberName = styled.p`
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  @media (orientation: portrait) {
    margin: 0 0 20px 0;
  }
`;

const MemberDescription = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
`;

export default Team;
