// Import of components
import Navigation from "../components/Navigation";

// Imports for multi language
import { useTranslation } from "react-i18next";

// Imports for styling
import styled from "styled-components";

const AboutUs = () => {
    const { t } = useTranslation();
  return (
    <StyledAboutUs>
      <Navigation />
      <AboutUsContent>
        <AboutUsHeading>{t("aboutus0.label")}</AboutUsHeading>
        <Services>
          <Service>
            <ServiceTitle>{t("marketplace0.label")}</ServiceTitle>
            <ServiceContent>
              {t("marketplace1.label")}
              <br />
              <br /> {t("marketplace2.label")}
              <br />
              <br /> {t("marketplace3.label")}
            </ServiceContent>
          </Service>
          <Service>
            <ServiceTitle>{t("smartcontract0.label")}</ServiceTitle>
            <ServiceContent>
             {t("smartcontract1.label")}
              <br />
              <br /> {t("smartcontract2.label")}
            </ServiceContent>
          </Service>
          <Service>
            <ServiceTitle>{t("mintingpage0.label")}</ServiceTitle>
            <ServiceContent>
              {t("mintingpage1.label")}
            </ServiceContent>
          </Service>
          <Service>
            <ServiceTitle>{t("community0.label")}</ServiceTitle>
            <ServiceContent>
              {t("community1.label")}
            </ServiceContent>
          </Service>
          <Service>
            <ServiceTitle>{t("activeadvice0.label")}</ServiceTitle>
            <ServiceContent>
             {t("activeadvice1.label")}
            </ServiceContent>
          </Service>
        </Services>
      </AboutUsContent>
    </StyledAboutUs>
  );
};

// Styled components
const StyledAboutUs = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #0b1d27;
  @media (orientation: portrait) {
    height: auto;
    min-height: 100vh;
  }
`;

const AboutUsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 120px 150px 5vh 150px;
  background: #0b1d27;
  @media (orientation: portrait) {
    padding: 150px 30px 40px 30px;
  }
`;

const AboutUsHeading = styled.h1`
  margin-bottom: 55px;
  font-weight: 400;
  font-size: 70px;
  line-height: 88px;
  color: #c4c4c4;
  @media (orientation: portrait) {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 25px;
  }
`;

const Services = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Service = styled.div`
  width: 250px;
  margin: 25px 22.5px 25px 22.5px;
  @media (orientation: portrait) {
    width: auto;
    margin: 25px 0 25px 0;
  }
`;

const ServiceTitle = styled.h1`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #ffffff;
`;

const ServiceContent = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
`;

export default AboutUs;
