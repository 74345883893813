// Import of components
import Navigation from "../components/Navigation";

// Imports for multi language
import { useTranslation } from "react-i18next";

// Import of images
import aboveTheFoldBG from "../assets/images/above-the-fold-bg.jpg";

// Imports for styling
import styled from "styled-components";

const Homepage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledAboveTheFold>
        <Navigation />
        <AboveTheFoldContent>
          <ContentHeading>
            <h1>WGMI</h1>
            <h2>Labs</h2>
          </ContentHeading>
          <ContentText>{t("welcome.label")}</ContentText>
        </AboveTheFoldContent>
      </StyledAboveTheFold>
    </div>
  );
};

// Styled Components
const StyledAboveTheFold = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url(${aboveTheFoldBG});
  background-repeat: repeat;
  background-size: cover;
`;

const AboveTheFoldContent = styled.div`
  width: 600px;
  margin: 30vh 80px 0 55vw;
  color: white;
  @media (orientation: landscape) and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 20vh 20px 0 30vw;
  }
  @media (orientation: portrait) {
    width: 90vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 30px 0 30px;
  }
`;

const ContentHeading = styled.div`
  display: flex;
  margin-bottom: 35px;
  h1 {
    margin-right: 7px;
    font-weight: 700;
    font-size: 70px;
    line-height: 88px;
  }
  h2 {
    font-weight: 400;
    font-size: 70px;
    line-height: 88px;
  }
  @media (orientation: portrait) {
    h1 {
      font-size: 40px;
      line-height: 50px;
    }
    h2 {
      font-size: 40px;
      line-height: 50px;
    }
  }
`;

const ContentText = styled.p`
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  @media (orientation: portrait) {
    font-size: 22px;
    line-height: 32px;
  }
`;

export default Homepage;
