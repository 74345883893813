// Imports for styling
import styled from "styled-components";

const Burgermenu = (props) => {
  return (
    <>
      {props.pathname === "/team" ? (
        <StyledBurgerTeam
          open={props.open}
          onClick={() => props.setOpen(!props.open)}
        >
          <div />
          <div />
          <div />
        </StyledBurgerTeam>
      ) : (
        <StyledBurger
          open={props.open}
          onClick={() => props.setOpen(!props.open)}
        >
          <div />
          <div />
          <div />
        </StyledBurger>
      )}
    </>
  );
};

// Styled Components
const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  margin-left: 15px;

  &:focus {
    outline: none;
  }

  div {
    width: 2.5rem;
    height: 0.25rem;
    background: white;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }

  div {
    @media (orientation: portrait) {
      background: ${({ open }) => (open ? "black" : "white")};
    }
  }
`;

const StyledBurgerTeam = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  margin-left: 15px;

  &:focus {
    outline: none;
  }

  div {
    width: 2.5rem;
    height: 0.25rem;
    background: #0b1d27;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export default Burgermenu;
