// Import of profile images
import Phillip from "../images/phillip.png";
import Luis from "../images/luis.png";
import Konstantin from "../images/konstantin.png";
import Tim from "../images/tim.png";

const teammembers = [
  {
    name: "Phillip",
    image: Phillip,
  },
  {
    name: "Luis",
    image: Luis,
  },
  {
    name: "Konsti",
    image: Konstantin,
  },
  {
    name: "Tim",
    image: Tim,
  },
];

export default teammembers;
