// Import of components
import Navigation from "../components/Navigation";

// Import of icons
import DiscordIcon from "../assets/icons/discord-icon.png";
import TwitterIcon from "../assets/icons/twitter-icon.png";

// Imports for multi language
import { useTranslation } from "react-i18next";

// Imports for styling
import styled from "styled-components";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <StyledContactUs>
      <Navigation />
      <ContactUsContent>
        <ContactUsHeading>{t("contactus0.label")}</ContactUsHeading>
        <p>WGMI Labs</p>
        <EmailAnchor
          href="mailto:phillip@wgmilabs.xyz"
          target="_blank"
          rel="noreferrer"
        >
          phillip@wgmilabs.xyz
        </EmailAnchor>
        <SocialAnchors>
          <a
            href="https://twitter.com/WGMILabs"
            target="_blank"
            rel="noreferrer"
          >
            <StyledTwitterIcon src={TwitterIcon} />
          </a>
          <a
            href="https://discord.gg/RSWtkKSKPq"
            target="_blank"
            rel="noreferrer"
          >
            <StyledDiscordIcon src={DiscordIcon} />
          </a>
        </SocialAnchors>
      </ContactUsContent>
    </StyledContactUs>
  );
};

// Styled components
const StyledContactUs = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #0b1d27;
`;

const ContactUsContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 150px;

  p {
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #c4c4c4;
  }
  @media (orientation: portrait) {
    margin: 0;
    padding: 60px 30px 40px 30px;
  }
`;

const ContactUsHeading = styled.h6`
  margin-bottom: 50px;
  font-weight: 400;
  font-size: 70px;
  line-height: 88px;
  color: #c4c4c4;
  @media (orientation: portrait) {
    font-size: 40px;
    line-height: 50px;
  }
`;

const EmailAnchor = styled.a`
  text-decoration: none;
  color: white;
`;

const SocialAnchors = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
`;

const StyledTwitterIcon = styled.img`
  width: 70px;
  height: auto;
  margin-right: 30px;
  transition: transform 0.8s;
  :hover {
    transform: scale(1.1);
  }
`;

const StyledDiscordIcon = styled.img`
  width: 90px;
  height: auto;
  margin-right: 30px;
  transition: transform 0.8s;
  :hover {
    transform: scale(1.1);
  }
`;

export default ContactUs;
