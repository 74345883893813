// Import of components
import LanguageSelector from "./LanguageSelector";

import "../i18n";

// Imports for multi language
import { useTranslation } from "react-i18next";

// Import of icons
import DiscordIcon from "../assets/icons/discord-icon.png";
import TwitterIcon from "../assets/icons/twitter-icon.png";

// Imports for styling
import styled from "styled-components";

const Menu = ({ open }) => {
  const { t } = useTranslation();

  return (
    <StyledMenu open={open}>
      <div>
        <HeadlineHolder>
          <CompanyName href="/">
            <p style={{ fontWeight: "700", marginRight: "7px" }}>WGMI</p>
            <p style={{ fontWeight: "400" }}>Labs</p>
          </CompanyName>
          <LanguageSelectorContainer>
            <LanguageSelector />
          </LanguageSelectorContainer>
        </HeadlineHolder>
        <Anchors>
          <a className="navigation" href="/about">
            {t("aboutus1.label")}
          </a>
          <a className="navigation" href="/team">
            {t("team0.label")}
          </a>
          <a className="navigation" href="/contact">
            {t("contactus1.label")}
          </a>
        </Anchors>
      </div>
      <ContactUs>
        <MailUs>
          <MailUsCTA>{t("reachout0.label")}</MailUsCTA>
          <MailAdress
            href="mailto:phillip@wgmilabs.xyz"
            target="_blank"
            rel="noreferrer"
          >
            phillip@wgmilabs.xyz
          </MailAdress>
        </MailUs>
        <VisitUs>
          <VisitUsCTA>{t("visitus0.label")}</VisitUsCTA>
          <VisitAddress>
            WGMI Labs
            <br /> Einsteinstraße 174 <br />
            D-81677 München
          </VisitAddress>
        </VisitUs>
        <FollowUs>
          <FollowUsCTA>{t("followus0.label")}</FollowUsCTA>
          <SocialMediaAnchors>
            <a
              href="https://twitter.com/WGMILabs"
              target="_blank"
              rel="noreferrer"
            >
              <StyledTwitterIcon src={TwitterIcon} />
            </a>
            <a
              href="https://discord.gg/RSWtkKSKPq"
              target="_blank"
              rel="noreferrer"
            >
              <StyledDiscordIcon src={DiscordIcon} />
            </a>
          </SocialMediaAnchors>
        </FollowUs>
      </ContactUs>
    </StyledMenu>
  );
};

// Styled Components
const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #c4c4c4;
  width: 600px;
  height: 100vh;
  padding: 40px 60px 40px 60px;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};

  .navigation {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.5rem;
    color: black;
    text-decoration: none;
    transition: color 0.3s linear;
    transition: transform 0.8s;
    :hover {
      transform: scale(1.05);
    }
  }
  @media (max-height: 750px) {
    .navigation {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 22px;
    }
  }
  @media (max-height: 650px) {
    .navigation {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 20px;
    }
  }
  @media (orientation: portrait) {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: 70px 30px 90px 30px;
  }
`;

const HeadlineHolder = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CompanyName = styled.a`
  display: flex;
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  color: #0b1d27;
  margin-right: 25px;
  @media (max-height: 750px) {
    margin-bottom: 30px;
    font-size: 26px;
    line-height: 32px;
  }
`;

const LanguageSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (orientation: landscape) {
    display: none;
  }
`;

const Anchors = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-height: 750px) {
    margin-bottom: 20px;
  }
  @media (max-height: 650px) {
    margin-bottom: 10px;
  }
`;

const ContactUs = styled.div`
  display: flex;
  flex-direction: column;
`;

const MailUs = styled.div`
  display: flex;
  flex-direction: column;
`;

const MailUsCTA = styled.p`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #0b1d27;
  @media (max-height: 750px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

const MailAdress = styled.a`
  margin-bottom: 30px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.5rem;
  line-height: 23px;
  color: #0b1d27;
  transition: transform 0.8s;
  :hover {
    transform: scale(1.05);
  }
  @media (max-height: 750px) {
    font-size: 22px;
    line-height: 24px;
  }
  @media (orientation: portrait) {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.1rem;
  }
`;

const VisitUs = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const VisitUsCTA = styled.p`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #0b1d27;
  @media (max-height: 750px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

const VisitAddress = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #0b1d27;
`;

const FollowUs = styled.div`
  display: flex;
  flex-direction: column;
`;

const FollowUsCTA = styled.p`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #0b1d27;
  @media (max-height: 750px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

const SocialMediaAnchors = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTwitterIcon = styled.img`
  width: 50px;
  height: auto;
  margin-right: 30px;
`;

const StyledDiscordIcon = styled.img`
  width: 60px;
  height: auto;
  margin-right: 30px;
`;

export default Menu;
