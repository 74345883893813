// Imports from i18
import { useTranslation } from "react-i18next";

// Import of icons
import German from "../assets/icons/germany.png";
import UnitedKingdom from "../assets/icons/united-kingdom.png";

// Imports from styled-components
import styled from "styled-components";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  if (localStorage.getItem("lng") === "de") {
    localStorage.setItem("lng", "de");
  } else if (localStorage.getItem("lng") === "en") {
    localStorage.setItem("lng", "en");
  } else {
    localStorage.setItem("lng", "de");
  }

  const changeToEnglish = () => {
    localStorage.setItem("lng", "en");
    i18n.changeLanguage("en");
  };

  const changeToGerman = () => {
    localStorage.setItem("lng", "de");
    i18n.changeLanguage("de");
  };

  return (
    <div style={{ cursor: "pointer" }}>
      {localStorage.getItem("lng") === "de" ? (
        <LanguageButton onClick={changeToEnglish}>
          <StyledFlag src={UnitedKingdom} alt="English" />
        </LanguageButton>
      ) : (
        <LanguageButton onClick={changeToGerman}>
          <StyledFlag src={German} alt="German" />
        </LanguageButton>
      )}
    </div>
  );
};

// Styled components
const LanguageButton = styled.button`
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const StyledFlag = styled.img`
  width: 30px;
  height: 30px;
`;

export default LanguageSelector;
