// Import of components
import Navigation from "../components/Navigation";

// Imports for multi language
import { useTranslation } from "react-i18next";

// Imports for styling
import styled from "styled-components";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <StyledPrivacyPolicy>
      <Navigation />
      <PrivacyPolicyContent>
        <PrivacyHeadline>{t("privacyHeadline")}</PrivacyHeadline>
        <ContentDescription>
          {t("privacyThirdPartyHeadlinea.label")}
        </ContentDescription>
        <ContentText>{t("privacyThirdPartySubHeadline.label")}</ContentText>
        <ContentSubHeadline>
          {t("privacyGoogleHeadline.label")}
        </ContentSubHeadline>
        <ContentText>{t("privacyGoogleText.label")}</ContentText>
      </PrivacyPolicyContent>
    </StyledPrivacyPolicy>
  );
};

// Styled components
const StyledPrivacyPolicy = styled.div`
  min-height: 100vh;
  width: 100vw;
  background: #0b1d27;
`;

const PrivacyPolicyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 120px 150px 5vh 150px;
  background: #0b1d27;
  @media (orientation: portrait) {
    padding: 150px 30px 40px 30px;
  }
`;

const PrivacyHeadline = styled.p`
  margin-bottom: 55px;
  font-weight: 400;
  font-size: 70px;
  line-height: 88px;
  color: #c4c4c4;
  @media (orientation: portrait) {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 25px;
  }
`;

const ContentDescription = styled.p`
  font-weight: 300;
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 20px;
`;

const ContentSubHeadline = styled.p`
  font-weight: 300;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 20px;
`;

const ContentText = styled.p`
  font-weight: 300;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 20px;
`;

export default PrivacyPolicy;
