// Import to create global valid styles
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    max-width: 100vw;
    font-family: 'Sora', sans-serif;}
`;

export default GlobalStyle;
