// Imports from react
import { useState } from "react";

// Import of components
import Burgermenu from "./Burgermenu";
import Menu from "./Menu";
import LanguageSelector from "./LanguageSelector";

// Imports for multi language
import { useTranslation } from "react-i18next";

// Import of icons
import DiscordIcon from "../assets/icons/discord-icon.png";
import TwitterIcon from "../assets/icons/twitter-icon.png";

// Imports for styling
import styled from "styled-components";

import "../i18n";

const Navigation = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const pathname = window.location.pathname;
  return (
    <StyledNavigation
      style={
        pathname === "/team"
          ? { background: "rgba(255, 255, 255, 0.8)" }
          : pathname === "/"
          ? { background: "none" }
          : { background: "rgba(11, 29, 39, 0.8)" }
      }
    >
      {pathname === "/team" ? (
        <a href="/" style={{ color: "#0B1D27" }}>
          <Logo>
            <h1>WGMI</h1>
            <h2> Labs</h2>
          </Logo>
        </a>
      ) : (
        <a href="/" style={{ color: "white" }}>
          <Logo>
            <h1>WGMI</h1>
            <h2> Labs</h2>
          </Logo>
        </a>
      )}
      {pathname === "/" ? (
        <Anchors>
          <SectionAnchors>
            <a href="/about">{t("aboutus1.label")}</a>
            <a href="/team">{t("team0.label")}</a>
            <a href="/contact">{t("contactus1.label")}</a>
          </SectionAnchors>
          <SocialMediaAnchors>
            <a
              href="https://twitter.com/WGMILabs"
              target="_blank"
              rel="noreferrer"
            >
              <StyledTwitterIcon src={TwitterIcon} />
            </a>
            <a
              href="https://discord.gg/RSWtkKSKPq"
              target="_blank"
              rel="noreferrer"
            >
              <StyledDiscordIcon src={DiscordIcon} />
            </a>
          </SocialMediaAnchors>
          <LanguageSelectorContainer>
            <LanguageSelector />
          </LanguageSelectorContainer>
          <Burgermenu open={open} setOpen={setOpen} />
          <Menu open={open} setOpen={setOpen} />
        </Anchors>
      ) : pathname === "/team" ? (
        <Anchors>
          <LanguageSelectorContainer>
            <LanguageSelector />
          </LanguageSelectorContainer>
          <Burgermenu pathname={pathname} open={open} setOpen={setOpen} />
          <Menu open={open} setOpen={setOpen} />
        </Anchors>
      ) : (
        <Anchors>
          <LanguageSelectorContainer>
            <LanguageSelector />
          </LanguageSelectorContainer>
          <Burgermenu pathname={pathname} open={open} setOpen={setOpen} />
          <Menu open={open} setOpen={setOpen} />
        </Anchors>
      )}
    </StyledNavigation>
  );
};

// Styled Components
const StyledNavigation = styled.div`
  position: fixed;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 100px 0 100px;
  a {
    text-decoration: none;
  }
  @media (orientation: portrait) {
    width: 100vw;
    height: auto;
    padding: 70px 30px 0 30px;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    margin-right: 7px;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
  }
  h2 {
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
  }
`;

const Anchors = styled.div`
  display: flex;
  align-items: center;
`;

const SectionAnchors = styled.div`
  display: flex;
  align-items: center;
  margin-right: 50px;
  a {
    margin-right: 30px;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    color: white;
    transition: transform 0.8s;
    :hover {
      transform: scale(1.1);
    }
  }
  @media (orientation: landscape) and (max-width: 1279px) {
    display: none;
  }
  @media (orientation: portrait) {
    display: none;
  }
`;

const SocialMediaAnchors = styled.div`
  display: flex;
  align-items: center;
  @media (orientation: portrait) {
    display: none;
  }
`;

const LanguageSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (orientation: portrait) {
    display: none;
  }
`;

const StyledTwitterIcon = styled.img`
  width: 50px;
  height: auto;
  margin-right: 30px;
`;

const StyledDiscordIcon = styled.img`
  width: 60px;
  height: auto;
  margin-right: 30px;
`;

export default Navigation;
